.Especialidades-wrapper {
  padding: 90px 20px;
  background-color: #0000001a;
}

.Especialidades-container {
  max-width: 1170px;
  margin: auto;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.Especialidades-container h2 {
  font-size: 38px;
  color: #212a34;
  margin-bottom: 10px;
}

.Especialidades-container p {
  font-size: 18px;
  line-height: 1.3;
}

.Especialidades-container ul {
  width: 100%;
  display: grid;
  text-align: center;
  gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  color: #212a34;
}

.Especialidades-container li {
  background-color: #fff;
  padding: 20px;
  border-left: 4px solid #48baef;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 10px 16px 45px -22px rgba(196, 196, 196, 0.47);
  line-height: 1.1em;
  display: flex;
  flex-direction: column;
  
  justify-content: space-between
}

.Especialidades-container li h3 {
  font-size: 18.5px;
  line-height: 1.2;
}

.Especialidades-container li span {
  display: block;
  color: #707070;
  line-height: 1.3;
  font-size: 16px;
  font-weight: 500;
  margin-top: 15px;
}
.Especialidades-container li button {
  margin-top: 10px;
  display: flex !important;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  color: #707070;
  font-size: 14px;
  border: none;
  width: 100%;
  text-transform: none;
  margin-top: 10px;
}

.Especialidades-container li button:hover{
  background-color: transparent;
  color: #707070;
  scale: 1.05;
}

.Especialidades-container li button i {
  color: #48baef;
  font-size: 40px;
}
