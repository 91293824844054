.sobre-wrapper{
    padding: 80px 20px;
padding-bottom: 120px;
}

.sobre-container{
    max-width: 1170px;
    margin: auto;
    display: flex;
    flex-direction: row-reverse;
    gap: 40px;
}

.sobre-content{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.sobre-container div{
    flex: 1;
}

.sobre-content h2{
    font-size: 34px;
    color: #212a34;
}

.sobre-content p{
    font-size: 18px;
    line-height: 1.3;
}

.sobre-img{
  background-image: url("../../assets/imgs/digital-forensics-_1_.webp");
  border-radius: 30px;
  background-size: cover; /* Ajusta o tamanho da imagem para cobrir todo o contêiner */
  background-position: center; /* Centraliza a imagem */
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
    .sobre-container{
        padding-top: 10px;
        flex-direction: column;
    }
}