.PrincipaisDesafios-wrapper {
    padding: 60px 20px;
    padding-top: 120px;
}

.PrincipaisDesafios-container {
    max-width: 1170px;
    margin: auto;
    display: flex;
    gap: 40px;
}

.PrincipaisDesafios-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.PrincipaisDesafios-container div {
    flex: 1;
}

.PrincipaisDesafios-content h2 {
    font-size: 34px;
    color: #212a34;
}

.PrincipaisDesafios-content p {
    font-size: 18px;
    line-height: 1.3;
}

.PrincipaisDesafios-img {
  background-image: url('../../assets/imgs/man-using-digital-tablet-psd-mockup-smart-technology\ \(1\).jpg');
  border-radius: 30px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
    .PrincipaisDesafios-container {
        padding-top: 10px;
        flex-direction: column;
    }
}
