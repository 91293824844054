.ExpertiseEquipe-wrapper {
    padding: 90px 20px;
}

.ExpertiseEquipe-container {
    max-width: 1170px;
    margin: auto;
    display: flex;
    
  align-items: center;
    gap: 40px;
}

.ExpertiseEquipe-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    
}

.ExpertiseEquipe-container div {
    flex: 1;
}

.ExpertiseEquipe-content h2 {
    font-size: 34px;
    color: #212a34;
}

.ExpertiseEquipe-content p {
    font-size: 18px;
    line-height: 1.3;
}

.ExpertiseEquipe-content ul{
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 18px;
}
.ExpertiseEquipe-content li{
    display: flex;
    align-items: center;
    gap: 5px;
}
.ExpertiseEquipe-content i{
    color: #48baef;
    font-size: 22px;
}

.ExpertiseEquipe-img {
  background-image: url('../../assets/imgs/expertise.jpg');
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 30px;
  min-height: 400px;
}


@media (max-width: 768px) {
    .ExpertiseEquipe-container {
        padding-top: 10px;
        flex-direction: column;
    }
}

/* Tracinho em baixo do h1;
font 14 no texto;
ícone na listagem; */
